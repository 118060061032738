<template>
  <div class="manage"
       id="manage">
    <div class="manage_title">现场安全管理平台</div>
    <el-row>
      <el-col :span="2">
        <div class="grid-content"></div>
      </el-col>
      <el-col :span="20">
        <div class="manage_top">
          <el-row :gutter="40">
            <el-col :span="8">
              <a href="http://kb.taigerui.cn"
                 target="_blank">
                <section>
                  <div class="card">
                    <div class="box">
                      <div class="imgBx manage_top_item">
                        <el-image class="manage_top_item_img"
                                  :src="manage_top_item1"></el-image>
                        <div class="manage_top_item_rightTop">平台级</div>
                        <div class="manage_top_item_title">全景安全业态</div>
                        <div class="manage_top_item_text">感知、告警、预测、辅助决策</div>
                      </div>
                      <div class="contentBx">
                        <div>
                          <h2>安全生产全域可视化</h2>
                          <p>利用三维立体建模及可视化技术，还原企业厂区、车间、设备，配套双预控、巡检、作业管理等安全治理工具,将安全管理及物联终端数据全域、动态展现及分析,形成企业安全生产一张图,提升企业安全生产感知、告警及预测能力。</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </a>
            </el-col>
            <el-col :span="8">
              <a href="http://kb.taigerui.cn"
                 target="_blank">
                <section>
                  <div class="card">
                    <div class="box">
                      <div class="manage_top_item imgBx">
                        <el-image class="manage_top_item_img"
                                  :src="manage_top_item2"></el-image>
                        <div class="manage_top_item_rightTop">平台级</div>
                        <div class="manage_top_item_title">数字孪生</div>
                        <div class="manage_top_item_text">设备接入、数据采集、虚实同频、远程操作、工艺仿真</div>
                      </div>
                      <div class="contentBx">
                        <div>
                          <h2>车间班组安全生产可视化</h2>
                          <p>基于先进行业企业的生产环境和生产设备，建设与实际企业情境对接的虚拟仿真实训环境，专业打造虚拟仿真车间场景，组合逻辑可选择产业链逻辑、人才培养逻辑、工序工艺逻辑、生产流程逻辑等，作为车间日常生产管理及班组文化建设及数字化转型的重要手段。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </a>
            </el-col>
            <el-col :span="8">
              <a href="http://kb.taigerui.cn"
                 target="_blank">
                <section>
                  <div class="card">
                    <div class="box">
                      <div class="manage_top_item imgBx">
                        <el-image class="manage_top_item_img "
                                  :src="manage_top_item3"></el-image>
                        <div class="manage_top_item_rightTop">平台级</div>
                        <div class="manage_top_item_title">数字应急</div>
                        <div class="manage_top_item_text">应急预案电子化、应急预案可视化、应急预案智能化</div>
                      </div>
                      <div class="contentBx">
                        <div>
                          <h2>应急预案可视化</h2>
                          <p>应急预案结构化分解,将组织机构与职责、应急资源、应急通讯录、应急流程等关键要素开发成相对独立又相互关联的结构化数据，同时结合现场生产、安全动态数据,对突发事件的演化过程进行分析,预测发展趋势、危害程度生成处置方案。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </a>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="2">
        <div class="grid-content"></div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="2">
        <div class="grid-content"></div>
      </el-col>
      <el-col :span="20">
        <div class="manage_center">
          <el-row :gutter="40">
            <el-col :span="12">
              <div class="manage_center_item">
                <el-row>
                  <el-col :span="8">
                    <div class="grid-content bg-purple">
                      <el-image class="manage_center_item_img"
                                style="border-radius: 16px;"
                                :src="manage_center_item1"></el-image>
                    </div>
                  </el-col>
                  <el-col :span="16">
                    <div class="grid-content bg-purple-light">
                      <div class="manage_center_item_rightTop">应用级</div>
                      <div class="manage_center_item_title">隐患排查</div>
                      <div class="manage_center_item_text">隐患排查治理APP以安全生产标准化和双控体系要求为开发设计依据,实现隐患全过程闭环管理。</div>
                      <div class="manage_center_item_botton">
                        <el-row>
                          <el-button plain>双预控</el-button>
                          <el-button plain>四色图</el-button>
                          <el-button plain>责任人</el-button>
                          <el-button plain>闭环管理</el-button>
                        </el-row>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="manage_center_item">
                <el-row>
                  <el-col :span="8">
                    <div class="grid-content bg-purple">
                      <el-image class="manage_center_item_img"
                                style="border-radius: 16px;"
                                :src="manage_center_item2"></el-image>
                    </div>
                  </el-col>
                  <el-col :span="16">
                    <div class="grid-content bg-purple-light">
                      <div class="manage_center_item_rightTop">应用级</div>
                      <div class="manage_center_item_title">例行巡检 </div>
                      <div class="manage_center_item_text">SaaS云平台为基础,智能工单模版、电子化巡检点、检查清单预设,兼容多种loT设备接入,提升巡检效率、提高巡检质量。</div>
                      <div class="manage_center_item_botton">
                        <el-row>
                          <el-button plain>生产巡检</el-button>
                          <el-button plain>安全巡检</el-button>
                          <el-button plain>设备巡检</el-button>
                          <el-button plain>临时检查</el-button>
                          <el-button plain
                                     style="margin-left:0px;margin-top:10px">巡检轨迹</el-button>
                        </el-row>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="2">
        <div class="grid-content"></div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="2">
        <div class="grid-content"></div>
      </el-col>
      <el-col :span="20">
        <div class="manage_bottom">
          <el-row :gutter="40">
            <el-col :span="6">
              <div class="manage_bottom_item">
                <div class="manage_bottom_item_rightTop">应用级</div>
                <el-row class="manage_bottom_item_top">
                  <el-col :span="6">
                    <div>
                      <el-image class="manage_bottom_item_img"
                                :src="manage_bottom_item1"></el-image>
                    </div>
                  </el-col>
                  <el-col :span="18">
                    <div>
                      <div class="manage_bottom_item_top_title">作业管理</div>
                      <div class="manage_bottom_item_top_text">良好作业实践</div>
                    </div>
                  </el-col>
                </el-row>
                <div class="manage_bottom_item_text">
                  现场作业安全措施落实管理,现场人员作业违规管理、作业行为风险监控管理,作业过程记录,规范作业人员行为,异常事件报警,提升风险管控。
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="manage_bottom_item">
                <div class="manage_bottom_item_rightTop">应用级</div>
                <el-row class="manage_bottom_item_top">
                  <el-col :span="6">
                    <div>
                      <el-image class="manage_bottom_item_img"
                                :src="manage_bottom_item2"></el-image>
                    </div>
                  </el-col>
                  <el-col :span="18">
                    <div>
                      <div class="manage_bottom_item_top_title">设备管理</div>
                      <div class="manage_bottom_item_top_text">数字化设备管理</div>
                    </div>
                  </el-col>
                </el-row>
                <div class="manage_bottom_item_text">
                  记录完整的设备信息以及所关联的保养计划、维护记录到期提醒、服务记录等,随时了解完整的设备状况。
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="manage_bottom_item">
                <div class="manage_bottom_item_rightTop">应用级</div>
                <el-row class="manage_bottom_item_top">
                  <el-col :span="6">
                    <div>
                      <el-image class="manage_bottom_item_img"
                                :src="manage_bottom_item3"></el-image>
                    </div>
                  </el-col>
                  <el-col :span="18">
                    <div>
                      <div class="manage_bottom_item_top_title">安全监督助手</div>
                      <div class="manage_bottom_item_top_text">预警降危零事故</div>
                    </div>
                  </el-col>
                </el-row>
                <div class="manage_bottom_item_text">
                  实现安全监督在现场查询法规的智能推送,同时为安全监督提供现场示险工具并拥有检查上报功能。现场安全检查过程信息可追溯，基础信息可共享，可靠易用。

                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="manage_bottom_item">
                <div class="manage_bottom_item_rightTop">应用级</div>
                <el-row class="manage_bottom_item_top">
                  <el-col :span="6">
                    <div>
                      <el-image class="manage_bottom_item_img"
                                :src="manage_bottom_item4"></el-image>
                    </div>
                  </el-col>
                  <el-col :span="18">
                    <div>
                      <div class="manage_bottom_item_top_title">班组云</div>
                      <div class="manage_bottom_item_top_text">班组深度管理</div>
                    </div>
                  </el-col>
                </el-row>
                <div class="manage_bottom_item_text">
                  班组开展自驱安全工作，自驱规范行为。
                  班组工作辅助：班组场景风险辨识、班组场景风险评估、班组风险管控、班组安全检查、班组规范作业分享
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="2">
        <div class="grid-content"></div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data () {
    return {
      manage_top_item1: require('../assets/manage/QUANYU.png'),
      manage_top_item2: require('../assets/manage/cejian.png'),
      manage_top_item3: require('../assets/manage/yingji.png'),
      manage_center_item1: require('../assets/manage/cen1.gif'),
      manage_center_item2: require('../assets/manage/cen2.gif'),
      manage_bottom_item1: require('../assets/manage/zuoyeguanli.png.png'),
      manage_bottom_item2: require('../assets/manage/shebeiq.png.png'),
      manage_bottom_item3: require('../assets/manage/jianduzhushou.png.png'),
      manage_bottom_item4: require('../assets/manage/banzuyun.png.png'),
    }
  }
}
</script>
<style lang="less" scoped>
.manage {
  width: 100%;
  height: 1389px;
  background: rgba(255, 253, 250, 1);
}
.manage_title {
  padding-top: 50px;
  padding-bottom: 50px;
  height: 71px;
  color: rgba(0, 0, 0, 1);
  font-size: 48px;
  font-weight: 600;
}
.manage_top {
}
.manage_top_item {
  height: 318px;
  background: linear-gradient(
    90deg,
    rgba(250, 250, 250, 1) 0%,
    rgba(242, 245, 252, 1) 100%
  );
  background-image: url("../assets/manage/111.png");
  box-shadow: 5px 3px 10px rgba(0, 55, 117, 0.25);
  border-radius: 4px;
  text-align: left;
}
.manage_top_item:hover {
  box-shadow: 0px 0px 0px rgba(0, 55, 117, 0.25);
  .manage_top_item_hover {
    top: 0px;
  }
}
.manage_top_item_hover {
  position: absolute;
  width: 500px;
  height: 318px;
  background: linear-gradient(
    180deg,
    rgba(235, 244, 255, 1) 0%,
    rgba(227, 244, 255, 0.95) 100%
  );
  //   padding: 40px;
  border: 2px solid #ffffff;
  border-radius: 4px;
  top: 340px;
  transition: all ease-in-out 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.manage_top_item_hover_text {
  padding: 20px 30px 20px 30px;
}
.manage_top_item_hover_botton {
  text-align: center;
  line-height: 50px;
  width: 134px;
  height: 50px;
  color: rgb(248, 246, 246);
  background: rgba(0, 119, 255, 1);
  box-shadow: 0px 2px 4px rgba(0, 56, 120, 0.25);
}
.manage_top_item_img {
  margin-left: 28px;
  margin-top: 6px;
  width: 202px;
  height: 202px;
}
.manage_top_item_rightTop {
  width: 124px;
  height: 46px;
  line-height: 46px;
  color: rgba(245, 245, 245, 1);
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  float: right;
  background: linear-gradient(
    90deg,
    rgba(49, 219, 222, 1) 0%,
    rgba(34, 78, 240, 1) 100%
  );
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 0px 4px 0px 9px;
}
.manage_top_item_title {
  height: 36px;
  margin-left: 34px;
  line-height: 36px;
  color: rgba(0, 0, 0, 1);
  font-size: 24px;
  font-weight: 800;
}
.manage_top_item_text {
  line-height: 24px;
  margin-left: 34px;
  margin-top: 12px;
  height: 24px;
  color: rgba(102, 102, 102, 1);
  font-size: 16px;
  font-weight: 400;
}
.manage_center {
  background: rgba(255, 253, 250, 1);
  //   padding-top: 26px;
  margin-top: 36px;
}
.manage_center_item {
  height: 302px;
  background: linear-gradient(
    90deg,
    rgba(247, 249, 250, 1) 0%,
    rgba(247, 249, 252, 1) 100%
  );
  box-shadow: 5px 3px 10px rgba(0, 55, 117, 0.25);
  border-radius: 4px;
  mix-blend-mode: normal;
  text-align: left;
}
.manage_center_item_rightTop {
  width: 124px;
  height: 46px;
  line-height: 46px;
  color: rgba(245, 245, 245, 1);
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  float: right;
  background: linear-gradient(
    90deg,
    rgba(49, 219, 222, 1) 0%,
    rgba(34, 78, 240, 1) 100%
  );
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 0px 4px 0px 9px;
}
.manage_center_item_title {
  padding-top: 34px;
  margin-left: 16px;
  height: 36px;
  line-height: 36px;
  color: rgba(34, 34, 34, 1);
  font-size: 24px;
  font-weight: 500;
}
.manage_center_item_text {
  margin-top: 18px;
  margin-left: 16px;
  margin-right: 31px;
  line-height: 26px;
  height: 48px;
  color: rgba(102, 102, 102, 1);
  font-size: 16px;
  font-weight: 400;
}
.manage_center_item_botton {
  margin-left: 16px;
  margin-top: 31px;
}
.manage_bottom {
  padding-top: 36px;
}
.manage_bottom_item {
  height: 418px;
  background: linear-gradient(
    90deg,
    rgba(247, 249, 250, 1) 0%,
    rgba(247, 249, 252, 1) 100%
  );
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  text-align: left;
}
.manage_bottom_item:hover {
  border-radius: 4px;
  box-shadow: 4px 12px 20px 0px rgb(123 130 165 / 26%);
}
.manage_bottom_item_rightTop {
  width: 124px;
  height: 46px;
  line-height: 46px;
  color: rgba(245, 245, 245, 1);
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  float: right;
  background: linear-gradient(
    90deg,
    rgba(49, 219, 222, 1) 0%,
    rgba(34, 78, 240, 1) 100%
  );
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 0px 4px 0px 9px;
}
.manage_bottom_item_top {
  padding-top: 93px;
  padding-left: 35px;
  padding-right: 35px;
}
.manage_bottom_item_top_title {
  margin-left: 10px;
  height: 36px;
  color: rgba(68, 68, 68, 1);
  font-size: 24px;
  font-weight: 800;
}
.manage_bottom_item_top_text {
  margin-top: 10px;
  margin-left: 10px;
  height: 24px;
  color: rgba(68, 68, 68, 1);
  font-size: 16px;
  font-weight: 400;
}
.manage_bottom_item_text {
  padding: 45px 35px 35px 35px;
  color: rgba(68, 68, 68, 1);
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
/deep/ .el-button {
  background-color: transparent;
}
section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  transform-style: preserve-3d;
  width: auto;
}
section .card {
  position: relative;
  width: 100%;
  height: 318px;
  transform-style: preserve-3d;
  perspective: 1000px;
}
section .card .box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: 1s ease;
}
section .card:hover .box {
  transform: rotateY(180deg);
}
section .card .box .imgBx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
section .card .box .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
section .card .box .contentBx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(129, 96, 204);
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
}
section .card .box .contentBx div {
  transform-style: preserve-3d;
  padding: 20px;
  background: linear-gradient(45deg, #fe0061, #ffeb3b);
  transform: translateZ(100px);
}
section .card .box .contentBx div h2 {
  color: #fff;
  font-size: 20px;
  letter-spacing: 1px;
}
section .card .box .contentBx div p {
  color: #fff;
  font-size: 16px;
}
</style>