<template>
  <div class="content"
       id="content">
    <div class="content_title">培训服务平台</div>
    <el-row>
      <el-col :span="2">
        <div class="grid-content"></div>
      </el-col>
      <el-col :span="20">
        <div class="content_top">
          <el-row :gutter="40">
            <el-col :span="8">
              <div class="content_top_item">
                <div class="content_top_item_rightTop">平台级</div>
                <el-image class="content_top_item_img"
                          :src="content_top_item1"></el-image>
                <div class="content_top_item_title">轻知云-在线学习平台</div>
                <div class="content_top_item_text">基于岗位能力模型的安全生产、应急管理在线培训平台,适用于企业入场教育、三级安全、特种作业、班组文化、工艺安全、设备操作“教学练考评”全过程。</div>
                <a href="http://ts2.andisk.com/"
                   target="_blank">
                  <div class="content_top_item_botton">了解详情</div>
                </a>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="content_top_item">
                <div class="content_top_item_rightTop">平台级</div>
                <el-image class="content_top_item_img"
                          :src="content_top_item2"></el-image>
                <div class="content_top_item_title">虚拟仿真实训教学平台</div>
                <div class="content_top_item_text">基于虚拟现实技术,多人、大空间协同演练平台,提升团队、个人的应急协同处置能力。适用于突发事件应急演练.应急预案验证、应急预案前瞻性研究。</div>
                <!-- <a href=""
                   target="_blank"> -->
                <div class="content_top_item_botton">了解详情</div>
                <!-- </a> -->
              </div>
            </el-col>
            <el-col :span="8">
              <div class="content_top_item">
                <div class="content_top_item_rightTop">平台级</div>
                <el-image style="height:138px;margin-top: 53px;margin-right: 50px;"
                          class="content_top_item_img"
                          :src="content_top_item3"></el-image>
                <div class="content_top_item_title">寰予———三维在线培训平台</div>
                <div class="content_top_item_text">基于元宇宙的核心要素,通过虚拟现实、数字孪生等技术真实还原工作场景及现场数据,打破物理空间的界限,快适组织不同地域的学员进行培训</div>
                <a href="http://yyz.taigerui.cn/"
                   target="_blank">
                  <div class="content_top_item_botton">了解详情</div>
                </a>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="2">
        <div class="grid-content"></div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="2">
        <div class="grid-content"></div>
      </el-col>
      <el-col :span="20">
        <div class="content_center">
          <el-row :gutter="40">
            <el-col :span="12">
              <div class="content_center_item">
                <el-row>
                  <el-col :span="8">
                    <div class="grid-content bg-purple">
                      <el-image class="content_center_item_img"
                                style="border-radius: 16px;"
                                :src="content_center_item1"></el-image>
                    </div>
                  </el-col>
                  <el-col :span="16">
                    <div class="grid-content bg-purple-light">
                      <div class="content_center_item_rightTop">服务级</div>
                      <div class="content_center_item_title">课程服务</div>
                      <div class="content_center_item_text">专业脚本编辑(新型课件-微课、教育游戏)，专业建模动画制作(交互方式多样、数据接入)，沉浸场景真实情景复现过程数据记录
                      </div>
                      <div class="content_center_item_botton">
                        <el-row>
                          <el-button plain>模拟演练课</el-button>
                          <el-button plain>虚拟仿真课</el-button>
                          <el-button plain>实拍课</el-button>
                          <el-button plain>直播课</el-button><br />
                          <el-button plain
                                     style="margin-left:0px;margin-top:10px">微课</el-button>
                        </el-row>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="content_center_item">
                <el-row>
                  <el-col :span="8">
                    <div class="grid-content bg-purple">
                      <el-image class="content_center_item_img"
                                style="border-radius: 16px;"
                                :src="content_center_item2"></el-image>
                    </div>
                  </el-col>
                  <el-col :span="16">
                    <div class="grid-content bg-purple-light">
                      <div class="content_center_item_rightTop">应用级</div>
                      <div class="content_center_item_title">培训一体机 </div>
                      <div class="content_center_item_text">培训一体机是一款随时随地开展安全培训的软硬件产品,采用工业化设计，集身份证读卡器、IC卡读卡器、答题器于一体,适用于在特殊环境下（无网、工况复杂)实施员工入场、三级安全、班组安全教育培训。
                      </div>
                      <div class="content_center_item_botton">
                        <el-row style="padding-top:30px">
                          <el-button plain>学习</el-button>
                          <el-button plain>练习</el-button>
                          <el-button plain>考试</el-button>
                        </el-row>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="2">
        <div class="grid-content"></div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data () {
    return {
      content_top_item1: require('../assets/content/456yun.png'),
      content_top_item2: require('../assets/content/456123.png'),
      content_top_item3: require('../assets/content/456plant.png'),
      content_center_item1: require('../assets/content/_8.gif'),
      content_center_item2: require('../assets/content/_9.gif'),
    }
  }

}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  height: 954px;
  background: linear-gradient(
    180deg,
    rgba(245, 245, 245, 1) 0%,
    rgba(242, 242, 242, 1) 100%
  );
}
.content_title {
  padding-top: 50px;
  padding-bottom: 50px;
  height: 71px;
  color: rgba(0, 0, 0, 1);
  font-size: 48px;
  font-weight: 600;
}
.content_top_item {
  position: relative;
  height: 318px;
  background: linear-gradient(
    90deg,
    rgba(240, 248, 255, 1) 0%,
    rgba(215, 229, 247, 1) 100%
  );
  box-shadow: 5px 3px 10px rgba(0, 55, 117, 0.25);
  border-radius: 4px;
  mix-blend-mode: normal;
  text-align: left;
}
.content_top_item_img {
  position: absolute;
  top: 80px;
  right: 0px;
}
.content_top_item_rightTop {
  width: 124px;
  height: 46px;
  line-height: 46px;
  color: rgba(245, 245, 245, 1);
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  float: right;
  background: linear-gradient(
    90deg,
    rgba(49, 219, 222, 1) 0%,
    rgba(34, 78, 240, 1) 100%
  );
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 0px 4px 0px 9px;
}
.content_top_item_title {
  padding-top: 52px;
  padding-left: 34px;
  height: 36px;
  mix-blend-mode: normal;
  color: rgba(0, 0, 0, 1);
  font-size: 24px;
  font-weight: 500;
}
.content_top_item_text {
  padding-top: 12px;
  padding-left: 34px;
  width: 260px;
  line-height: 26px;
  height: 72px;
  color: rgba(102, 102, 102, 1);
  font-size: 16px;
  font-weight: 400;
}
.content_top_item_botton {
  text-align: center;
  margin-top: 70px;
  margin-left: 40px;
  line-height: 42px;
  width: 115px;
  height: 42px;
  background: rgba(15, 121, 242, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  color: rgba(225, 236, 251, 1);
  font-size: 18px;
  font-weight: 500;
}
.content_center {
  padding-top: 36px;
}
.content_center_item {
  height: 302px;
  background: linear-gradient(
    90deg,
    rgba(247, 249, 250, 1) 0%,
    rgba(247, 249, 252, 1) 100%
  );
  box-shadow: 5px 3px 10px rgba(0, 55, 117, 0.25);
  border-radius: 4px;
  mix-blend-mode: normal;
  text-align: left;
}
.content_center_item_rightTop {
  width: 124px;
  height: 46px;
  line-height: 46px;
  color: rgba(245, 245, 245, 1);
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  float: right;
  background: linear-gradient(
    90deg,
    rgba(49, 219, 222, 1) 0%,
    rgba(34, 78, 240, 1) 100%
  );
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 0px 4px 0px 9px;
}
.content_center_item_title {
  padding-top: 34px;
  margin-left: 16px;
  height: 36px;
  line-height: 36px;
  color: rgba(34, 34, 34, 1);
  font-size: 24px;
  font-weight: 500;
}
.content_center_item_text {
  margin-top: 18px;
  margin-left: 16px;
  margin-right: 31px;
  line-height: 24px;
  height: 48px;
  color: rgba(102, 102, 102, 1);
  font-size: 16px;
  font-weight: 400;
}
.content_center_item_botton {
  margin-left: 16px;
  margin-top: 31px;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
/deep/ .el-button {
  background-color: transparent;
}
a {
  text-decoration: none;
}
</style>