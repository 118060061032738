import { render, staticRenderFns } from "./footPage.vue?vue&type=template&id=3a8cb9a7&scoped=true&"
import script from "./footPage.vue?vue&type=script&lang=js&"
export * from "./footPage.vue?vue&type=script&lang=js&"
import style0 from "./footPage.vue?vue&type=style&index=0&id=3a8cb9a7&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a8cb9a7",
  null
  
)

export default component.exports