<template>
  <div class="contact"
       :style="{backgroundImage: 'url('+bg+')'}"
       id="contact">
    <div class="contact_button">
      <el-button type="primary"
                 style="margin-top:120px;"
                 v-show="botshow"
                 slot="reference"
                 @click="btnhandle()">联系我们</el-button>
      <div v-show="btnclose"
           class="showDiv"
           @click="btnclosehandle()">
        <div class="foot_item_text">联系我们</div>
        <div class="foot_item_text">手机咨询：150-2200-9676</div>
        <div class="foot_item_text2">189-2003-6702</div>
        <div class="foot_item_text">热线咨询：022-59867799</div>
        <div class="foot_item_text">企业邮箱：taigeritj@163.com</div>
        <div class="foot_item_text">企业地址：天津开发区第四大街天大科技园A1座-605室</div>
        <el-image class="foot_item_img"
                  :src='foot_item'></el-image>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      foot_item: require('../assets/foot/ewms.png'),
      botshow: true,
      btnclose: false,
      bg: require('../assets/caotact/ding.png')
    }
  },
  mounted () { },
  methods: {
    btnhandle () {
      this.botshow = false
      this.btnclose = true
      this.bg = require('../assets/caotact/ding2.png')
    },
    btnclosehandle () {
      this.botshow = true
      this.btnclose = false
      this.bg = require('../assets/caotact/ding.png')
    }
  }

}
</script>
<style lang="less" scoped>
.contact {
  height: 318px;
  width: 100%;
  background-color: antiquewhite;

  //   background-image: url("../assets/caotact/ding.png");
}
.contact_button {
  height: auto;
  width: 30%;
  margin: 0 auto;
  padding-top: 28px;
}
.showDiv {
  margin: 0 auto;
  width: 497px;
  height: 260px;
  background: linear-gradient(
    90deg,
    rgba(245, 247, 250, 0.95) 0%,
    rgba(245, 248, 255, 0.8) 100%
  );
  border: 2px solid rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25),
    inset 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
}
.foot_item_text {
  padding-top: 16px;
  padding-left: 25px;
  text-align: left;
  height: 24px;
  line-height: 24px;
  color: rgba(41, 41, 41, 1);
  font-size: 18px;
  font-weight: 600;
}
.foot_item_text2 {
  padding-top: 16px;
  padding-left: 110px;
  color: rgba(41, 41, 41, 1);
  text-align: left;
  height: 24px;
  line-height: 24px;
  font-size: 18px;
  font-weight: 600;
}
.foot_item_img {
  position: absolute;
  margin-top: -180px;
  margin-left: 80px;
  height: 100px;
  width: 100px;
}
/deep/ .el-button {
  font-size: 24px;
  padding: 12px 40px;
}
</style>