<template>
  <div class="center">
    <div class="center_item"
         id="container">
      <div id="slide3"></div>
      <div id="slide2"></div>
      <div id="slide1"></div>
    </div>
  </div>
</template>
<script>
// import $ from "jquery";
export default {
  data () {
    return {
      // backgroudImg: require('../assets/center/tan1.png')
    }
  },
  mounted () {
  },
  created () {
    this.ta1()
  },
  methods: {
    // ta1 () {
    //   this.backgroudImg = require('../assets/center/tan1.png')
    // },
    // ta2 () {
    //   this.backgroudImg = require('../assets/center/tan1.png')
    // },
  }
}
</script>
<style lang="less" scoped>
.center {
  width: 100%;
  height: 116px;
  background: linear-gradient(
    90deg,
    rgba(232, 248, 252, 1) 0%,
    rgba(208, 239, 247, 1) 100%
  );
  background-image: url("../assets/center/bj.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
}
.center_item {
  width: 100%;
  height: 116px;
  margin: 0 auto;
  background-size: 100% auto;
  background-repeat: no-repeat;
  //   padding-top: 20px;
}
#container {
  width: 100%;
  height: 116px;
  margin: 0 auto;
  position: relative;
  border-radius: 50px;
}
#container div {
  width: 100%;
  height: 116px;
  position: absolute;
  background-size: cover;
}
#slide1 {
  background-image: url("../assets/center/tan11.png");
  animation: slide1 12s infinite;
}

@keyframes slide1 {
  0%,
  10% {
    opacity: 1;
  }

  25%,
  100% {
    opacity: 0;
  }
}

#slide2 {
  background-image: url("../assets/center/tan12.png");
  animation: slide2 12s infinite;
}

@keyframes slide2 {
  0%,
  35% {
    opacity: 1;
  }

  50%,
  100% {
    opacity: 0;
  }
}
#slide3 {
  background-image: url("../assets/center/tan11.png"); /*额外添加的最下层图片，应与最上层图片一致，
        这样在animation周期变更时，视觉上能平顺过渡*/
}
</style>