<template>
  <div class="tab"
       id="tab">
    <div class="manage_title">行业应用</div>
    <el-row>
      <el-col :span="2">
        <div class="grid-content"></div>
      </el-col>
      <el-col :span="20">
        <el-row>
          <el-col :span="9">
            <div class="new_item">
              <el-tabs :tab-position="tabPosition"
                       style="height: 500px;"
                       @tab-click="handleClick">
                <el-tab-pane label="石油与化工">
                  <div class="tab_item_text">
                    <h4>虚拟采油安全生产在线演练 </h4>
                    <p>基于元宇宙培训平台,快速组织不同地域、不同岗位的学员以虚拟人物形象进行隐患查找、良好作业实践、工艺、突发事件协同处置培训,学员在同一场景下进行多逻辑的组合协同交互,通过游戏化的方式编排写实的剧情充分调动员工的学习热情。
                    </p>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="民用航空">
                  <div class="tab_item_text">
                    <h4>民用航空器突发事件虚拟仿真演练</h4>
                    <p>
                      基于教学体系，利用虚拟现实技术，在VR设备的基础上集成动捕设备及力反馈数据手套,实现多人协同,动作数据采集,现实与虚拟同频且虚拟演练可多视角观摩的教学虚拟平台。为虚拟仿真实训教学体系专业人才培养赋能。
                    </p>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="船舶与海工">
                  <div class="tab_item_text">
                    <h4>海上重工建造场地安全可视化</h4>
                    <p>
                      项目针对船舶建造场地面积大，特种作业多的特点,建立了从厂区到车间安全生产全域可视，通过隐患排查、作业票管理、智能化巡检等安全治理工具应用提升整体安全管理水平。
                    </p>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-col>
          <el-col :span="15">
            <div class="new_item"
                 style="background-color: #17831c;">
              <el-image :src="backgroudImg"></el-image>
            </div>
          </el-col>
        </el-row>
        <div class="tab_item">
        </div>
      </el-col>
      <el-col :span="2">
        <div class="grid-content"></div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data () {
    return {
      tabPosition: 'top',
      backgroudImg: require('../assets/tab/tab1.png'),
      tabimg1: require('../assets/tab/tab1.png'),
      tabimg2: require('../assets/tab/tab2.png'),
      tabimg3: require('../assets/tab/tab3.png'),
    };
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab.paneName, event);
      if (tab.paneName == 0) {
        this.backgroudImg = require('../assets/tab/tab1.png')
      } else if (tab.paneName == 1) {
        this.backgroudImg = require('../assets/tab/tab2.png')
      } else {
        this.backgroudImg = require('../assets/tab/tab3.png')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.tab {
  width: 100%;
  height: 880px;
  //   background-image: url("../assets/tab/bg.png");
  background-repeat: no-repeat;
  background-size: 117%;
}
.manage_title {
  padding-top: 50px;
  padding-bottom: 30px;
  height: 71px;
  color: rgb(10, 10, 10);
  font-size: 48px;
  font-weight: 600;
}
.tab_item {
  padding-top: 20px;
}
.new_item {
  height: 562px;
  width: 100%;
}
.tab_img {
  padding: 30px 80px 50px 50px;

  background: rgba(7, 7, 7, 0.7);
  backdrop-filter: blur(10px);
  height: 400px;
}
.tab_item_text {
  color: white;
  text-align: left;
  height: 400px;
  padding: 30px 40px 50px 40px;
  h4 {
    letter-spacing: 3px;
    font-size: 30px;
  }
  p {
    line-height: 38px;
    letter-spacing: 3px;
    font-size: 20px;
    font-weight: 500;
  }
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
/deep/ .el-tabs__nav {
  float: none;
}
/deep/ .el-tabs__item {
  //   padding: 0 50px;
  height: 80px;
  width: 33.333%;
  box-sizing: border-box;
  line-height: 80px;
  display: inline-block;
  list-style: none;
  font-size: 20px;
  font-weight: 600;
  color: #f5f6f8;
  position: relative;
}
/deep/ .el-tabs__item :hover {
  color: #1567bd;
}
/deep/.el-tabs__item.is-active {
  color: #1567bd;
}
/deep/.el-tabs__active-bar {
  height: 0px;
}
/deep/ .tab_item_text {
  background: rgba(7, 7, 7, 0.8);
  backdrop-filter: blur(10px);
  height: 403px;
}
/deep/ .el-tabs__nav-scroll {
  background: rgba(7, 7, 7, 0.8);
  backdrop-filter: blur(10px);
}
/deep/ .el-tabs__header {
  margin: 0px;
}
</style>