<template>
  <div class="foot"
       id="foot">
    <el-row>
      <el-col :span="2">
        <div class="grid-content"></div>
      </el-col>
      <el-col :span="20">
        <el-row>
          <el-col :span="16">
            <div class="foot_item">
              <div class="foot_item_title">企业生态</div>
              <el-image style="margin-top:20px"
                        :src="foot_item"></el-image>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="foot_item_title">公司简介</div>
            <div class="foot_item_text">泰格瑞（天津）科技发展有限公司是国内领先的企业安全生产数字化服务商，致力于把数字化与安全管理、应急演练安全培训进行深度融合，通过数字化为安全生产赋能为不同行业提供面向场景的工业互联网＋安全管理、模拟演练、安全培训解决方案；提供安全管理、仿真演练、安全培训三大平台、系列软件工具及物联终端产品；构建感知，告警，预测，人机协同的智慧安全生态。</div>
            <div style="margin-top:10px">
              <div class="foot_item_title">使命愿景</div>
              <div class="foot_item_text">愿景：创行业领先的安全生产数字化生态品牌；实现生态各方的价值最大化。使命：打造科技、产品、数据、生态相结合的安全生产赋能平台体系，为行业提供安全生产数字化转型解决方案，为企业创值，实现生态各方增值共享的双价值循环。</div>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="2">
        <div class="grid-content"></div>
      </el-col>
    </el-row>
    <contact-us></contact-us>
    <div class="foot_text">Copyright ©2020-2030泰格瑞(天津)科技发展有限公司版权所有&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </div>
  </div>
</template>
<script>
import contactUs from './contactUs.vue'

export default {
  components: {
    contactUs
  },
  data () {
    return {
      foot_item: require('../assets/foot/hezuo.png')
    }
  }

}
</script>
<style lang="less" scoped>
.foot {
  width: 100%;
  height: 474px;
  background: rgb(252, 250, 250);
  color: rgb(14, 13, 13);
}
.foot_item {
  padding-right: 174px;
}
.foot_item_title {
  padding-top: 42px;
  text-align: left;
  height: 34px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 2px solid rgba(166, 166, 166, 1);
}
.foot_item_mintitle {
  padding-top: 16px;
  text-align: left;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
}
.foot_item_text {
  padding-top: 16px;
  text-align: left;
  height: 104px;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
}
.foot_item_text2 {
  padding-top: 16px;
  padding-left: 70px;
  text-align: left;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
}
.foot_item_img {
  top: 20px;
  height: 150px;
  float: right;
}
.foot_text {
  padding-top: 23px;
  color: rgba(138, 138, 138, 1);
  font-size: 14px;
  height: 50px;
  font-weight: 400;
  background-color: #444;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>