<template>
  <div id="app">
    <div class="header_menu">
      <el-row>
        <el-col :span="2">
          <div class="grid-content bg-purple"></div>
        </el-col>
        <el-col :span="3">
          <div class="grid-content bg-purple top_logo">
            <el-image style="width: auto; height: 43px"
                      :src="url"
                      fit="contain"></el-image>
          </div>
        </el-col>
        <el-col :span="17">
          <div class="grid-content bg-purple">
            <el-menu style="margin-top:-8px"
                     class="el-menu-demo"
                     mode="horizontal">
              <el-menu-item index="1"><a href="#manage">现场平台</a></el-menu-item>
              <el-menu-item index="2"><a href="#content">培训平台</a></el-menu-item>
              <el-menu-item index="3"><a href="#tab">行业应用</a></el-menu-item>
              <el-menu-item index="4"><a href="#contact">联系我们</a></el-menu-item>
              <!-- <el-menu-item index="4"><a href="#foot">关于我们</a></el-menu-item> -->
              <div class="phone_icon">
                <el-image :src="phone_icon"></el-image>
              </div>
              <div class="phone_text">
                <el-image :src="phone_text"></el-image>
              </div>
            </el-menu>
          </div>
        </el-col>
        <el-col :span="2">
          <div class="grid-content bg-purple"></div>
        </el-col>
      </el-row>
    </div>
    <top-page></top-page>
    <center-page></center-page>
    <manage-page></manage-page>
    <content-page></content-page>
    <tab-page></tab-page>
    <foot-page></foot-page>
  </div>
</template>

<script>
import topPage from './components/topPage.vue'
import centerPage from './components/centerPage.vue'
import managePage from './components/managePage.vue'
import contentPage from './components/contentPage.vue'
import footPage from './components/footPage.vue'
import tabPage from './components/tabPage.vue'

export default {
  name: 'App',
  components: {
    topPage,
    centerPage,
    managePage,
    contentPage,
    footPage,
    tabPage
  },
  data () {
    return {
      url: require('./assets/logo-bai.png'),
      phone_icon: require('./assets/24gf-telephone2.png'),
      phone_text: require('./assets/022-59867799.png'),
    }
  }
}
</script>

<style lang="less" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
.header_menu {
  margin: 20px;
}
.top_logo {
  text-align: left;
}
.phone_icon {
  position: absolute;
  margin-top: 20px;
  margin-left: 1100px;
}
.phone_text {
  position: absolute;
  margin-top: 13px;
  margin-left: 1130px;
}
/deep/ .el-menu.el-menu--horizontal {
  border-bottom: solid 0px #e6e6e6;
  color: #222;
}
/deep/ .el-menu-item {
  padding: 0 80px;
  font-size: 22px;
  a {
    color: #444;
  }
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
a {
  text-decoration: none;
  font-weight: 600;
}
/deep/ .el-menu--horizontal > .el-menu-item {
  color: #444;
}
</style>
