import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// main.js
import lottie from 'vue-lottie'
import Tabs from "vue-material-tabs";
import VideoBg from 'vue-videobg';
import VueVideoPlayer from 'vue-video-player' // 视频播放器
import 'video.js/dist/video-js.css'

Vue.use(VueVideoPlayer)
Vue.use(Tabs);
Vue.component('lottiePage', lottie)
Vue.component('VideoBg', VideoBg)

Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
    render: h => h(App),
}).$mount('#app')