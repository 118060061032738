<template>
  <div class="top">
    <div class="title">
      <div class="title1">工业互联网+</div>
      <div class="title2">安全生产可视化 岗位能力提升</div>
      <div class="title3">专注企业安全生产数字化赋能</div>
    </div>
    <div class="videoContainer">
      <video class="fullscreenVideo"
             id="bgVid"
             playsinline=""
             autoplay=""
             muted=""
             loop="">
        <source src="../assets/top/ppp.mp4"
                type="video/mp4">
      </video>
    </div>

  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  }
}
</script>
<style lang="less" scoped>
.top {
  width: 100%;
  height: 840px;
  background: linear-gradient(
    90deg,
    rgba(195, 223, 250, 1) 0%,
    rgba(138, 180, 242, 1) 100%
  );

  //   background-image: url("../assets/top/111852.png");
  background-size: 101% auto;
  background-repeat: no-repeat;
}
.title {
  padding-left: 8%;
  margin-top: 15%;
  padding-top: 60px;
  position: absolute;
  width: 600px;
  height: 300px;
  background-color: rgba(0, 0, 0, 0.4);
}
.title1 {
  z-index: 3;
  height: 100px;
  width: 500px;
  /** 文本1 */
  font-size: 82px;
  font-weight: 800;
  letter-spacing: 0px;
  line-height: 82px;
  // color: rgb(91, 148, 214);
  text-align: left;
  background: -webkit-linear-gradient(
    315deg,
    rgba(49, 219, 222, 1) 0%,
    rgba(34, 78, 240, 1) 100%
  );
  // text-shadow: 5px 5px 0px rgba(0, 0, 0, 0.5);
  vertical-align: top;
  // background: -webkit-linear-gradient(315deg, #3698e9 25%, rgb(4, 81, 196));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.title2 {
  height: 120px;
  width: 100%;
  z-index: 5;
  opacity: 1;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  /** 文本1 */
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 120px;
  color: rgba(240, 252, 255, 1);
  text-align: left;
  vertical-align: top;
}
.title3 {
  height: 25px;
  z-index: 5;
  opacity: 1;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  /** 文本1 */
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 25px;
  color: rgba(240, 252, 255, 1);
  text-align: left;
  vertical-align: top;
}
.videoContainer {
  //   position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  //   z-index: 1;
}

.videoContainer:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  //   background: rgba(25, 29, 34, 0.65);
}
</style>